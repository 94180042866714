import {computed} from "vue"
import {useStore} from "vuex"

const useUsuarios = () => {
  const store = useStore()

  const getUsuarios = async (busqueda) => {
    await store.dispatch("usuarios/getUsuarios", busqueda)
  }

  const setBusqueda = (busqueda) => {
    store.dispatch("usuarios/setBusqueda", busqueda)
  }

  const getUsuarioInfo = async (usuario) => {
    return await store.dispatch("usuarios/getUsuarioInfo", {usuario})
  }

  const setGuardarUsuario = async (usuario) => {
    return await store.dispatch("usuarios/setGuardarUsuario", usuario)
  }

  const setEliminarUsuario = async (usuario) => {
    return await store.dispatch("usuarios/setEliminarUsuario", {usuario})
  }

  const getUsuariosCatalogos = async () => {
    await store.dispatch("usuarios/getUsuariosCatalogos")
  }

  const busquedaIni = {
    filtro: "",
  }

  const formaUsuarioIni = {
    id: "",
    nombre: "",
    email: "",
    password: "",
    permisos: [],
  }

  return {
    getUsuarios,
    setBusqueda,
    getUsuarioInfo,
    setGuardarUsuario,
    setEliminarUsuario,
    getUsuariosCatalogos,
    busquedaIni,
    formaUsuarioIni,
    usuarios: computed(() => store.getters["usuarios/usuarios"]),
    loading: computed(() => store.getters["usuarios/loading"]),
    busqueda: computed(() => store.getters["usuarios/busqueda"]),
    menus: computed(() => store.getters["usuarios/menus"]),
  }
}

export default useUsuarios
