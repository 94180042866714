<template>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header card-header-sm-stretch">
        <h3 class="card-title">Captura del Usuario</h3>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs nav-line-tabs">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-bs-toggle="tab"
              href="#kt_tab_pane_1"
              >Generales</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2"
              >Permisos</a
            >
          </li>
        </ul>

        <div class="tab-content">
          <div
            class="tab-pane fade active show"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <div class="row mt-5">
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control form-nombre"
                    v-model="formaUsuario.name"
                    id="forma_nombre"
                  />
                  <label for="forma_nombre">Nombre completo</label>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control form-email"
                    v-model="formaUsuario.email"
                    id="forma_email"
                  />
                  <label for="forma_email">Correo Electrónico</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input
                    type="password"
                    class="form-control form-password"
                    v-model="formaUsuario.password"
                    id="forma_password"
                  />
                  <label for="forma_password">Contraseña</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input
                    type="password"
                    class="form-control form-password2"
                    v-model="formaUsuario.password2"
                    id="forma_password2"
                  />
                  <label for="forma_password2">Confirmar contraseña</label>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <div class="row mt-5">
              <div class="col-md-4" v-for="menu in menus" :key="menu.id">
                <div class="card">
                  <div class="card-header">
                    <p>{{ menu.menu }}</p>
                  </div>
                  <div class="card-body">
                    <table class="table table-sm">
                      <tr
                        v-for="sm in menu.submenus"
                        :key="sm.id"
                        class="border"
                      >
                        <td class="text-center">
                          <input
                            type="checkbox"
                            v-model="formaUsuario.permisos"
                            :value="sm.id"
                          />
                        </td>
                        <td>{{ sm.submenu }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          @click="handleGuardar"
        >
          GUARDAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import useUsuarios from "../composables/useUsuarios";

export default {
  props: ["cid"],
  setup(props) {
    const router = useRouter();
    const {
      busqueda,
      getUsuarios,
      formaUsuarioIni,
      getUsuarioInfo,
      setGuardarUsuario,
      getUsuariosCatalogos,
      menus,
    } = useUsuarios();
    const formaUsuario = ref(formaUsuarioIni);

    if (props.cid) {
      getUsuarioInfo(props.cid).then((result) => {
        if (result.ok) {
          formaUsuario.value = { ...result.info };
          formaUsuario.value.permisos = result.permisos;
        } else {
          return router.push({ name: "usuarios" });
        }
      });
    } else {
      formaUsuario.value = { ...formaUsuarioIni };
    }

    getUsuariosCatalogos();
    const handleGuardar = () => {
      setGuardarUsuario({ ...formaUsuario.value }).then((result) => {
        if (result.ok) {
          Swal.fire(
            "Exito",
            "Se ha guardado el usuario correctamente",
            "success"
          );
          getUsuarios(busqueda.value);

          return router.push({ name: "usuarios" });
        } else {
          Swal.fire("Atención", result.message, "error");
        }
      });
    };

    return {
      handleGuardar,
      formaUsuario,
      menus,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>